import { formatNumber, checkAvailability } from "./number";
import { EURO_SYMBOL } from "./regex";

export const formatPrice = (price) => `${price} ${EURO_SYMBOL}`;
export const getFormatedPrice = (price) => formatPrice(formatNumber(price));
export const decreseAmount = (amount) => (amount - 1 <= 0 ? 1 : amount - 1);
export const increaseAmount = (amount, stock) => (checkAvailability(amount + 1, stock) ? amount : amount + 1);

export const getCartItemsTotal = (cart) =>
  cart.length
    ? cart.map(({ amount }) => amount).reduce((accumulator, currentValue) => accumulator + currentValue)
    : 0;

export const getCartSumTotal = (cart) =>
  cart.length
    ? cart
        .map(({ amount, price }) => amount * price)
        .reduce((accumulator, currentValue) => accumulator + currentValue)
    : 0;

export const sortByStock = (rows) => rows.sort((a, b) => b.stock - a.stock);
