import allesTexts from "data/texts";

// constants
export const FIRST_PER_PAGE = 50;
export const PER_PAGE = 99999;
export const PER_PAGE_HOME = 4;
export const PER_PAGE_ALLES = 3;
export const CATEGORY_PAGE = "category";
export const FOCUS_PAGE = "focus";

// urls web
export const HOMEPAGE_URL = "/";

// urls eshop
export const BASKET_URL = "/basket";
export const PROFILE_URL = "/user-account";
export const LOGIN_URL = "/login";
export const REGISTER_URL = "/register";
export const LOST_PW_URL = "/forgotten-password";
export const RESET_PW_URL = "/reset-passsword";
export const ORDER_USER_DATA_URL = "/order";
export const ORDER_DELIVEY_URL = "/order-shipping-and-payment";
export const ORDER_SUMMARY_URL = "/order-summary";
export const ORDER_COMPLETED_URL = "/payment/success";
export const SEARCH_URL = "/search";
export const TAGS_URL = "/tags";

// Header active section
export const HEADER_CATEGORY = "category";
export const HEADER_FOCUS = "focus";

// Categories
export const CATEGORIES = {
  1: { name: "Malen", content: allesTexts.malen },
  2: { name: "Zeichnen", content: allesTexts.zeichnen },
  3: { name: "Schreiben", content: allesTexts.schreiben },
  4: { name: "Sonstige", content: allesTexts.sonstige },
};

export const CATEGORIES_NAMES = [
  CATEGORIES[1].name,
  CATEGORIES[2].name,
  CATEGORIES[3].name,
  CATEGORIES[4].name,
];

export const FOCUS_CATEGORIES = {
  1: { name: "Hobby", content: allesTexts.hobby },
  2: { name: "Kreativität", content: allesTexts.kreativitat },
  3: { name: "Schule", content: allesTexts.schule },
  4: { name: "Büro", content: allesTexts.buro },
};

export const FOCUS_NAMES = ["Büro", "Hobby/Handwerk", "Kunst/Kreativität", "Schule/Spielen"];

// Menu
export const main_menu = [
  { url: "/alles", name: "menu-alles" },
  { url: "/category/malen", name: "menu-category-1" },
  { url: "/category/zeichnen", name: "menu-category-2" },
  { url: "/category/schreiben", name: "menu-category-3" },
  { url: "/category/sonstige", name: "menu-category-4" },
];

export const focus_menu = [
  { url: "/alles", name: "menu-alles" },
  { url: "/focus/hobby-handwerk", name: "menu-focus-1" },
  { url: "/focus/kunst-kreativitaet", name: "menu-focus-2" },
  { url: "/focus/schule-spielen", name: "menu-focus-3" },
  { url: "/focus/buero", name: "menu-focus-4" },
];

// Window sizes
export const windowSizes = {
  screen_xs: 340,
  screen_sm: 576,
  screen_md: 768,
  screen_lg: 992,
  screen_xlg: 1200,
  screen_xl: 1440,
  screen_xxl: 2400,
};

// Query
export const REGISTER_FORM_URL = "/auth/register";
export const LOGIN_FORM_URL = "/auth/login";
export const LOGOUT_URL = "/auth/logout";
export const LOST_PASSWORD_URL = "/auth/lost-password";
export const CHANGE_PASSWORD_URL = "/auth/change-password";
export const CONTACT_FORM_URL = "/contact-form/4";
export const REFRESH_TOKEN_URL = "/auth/refresh";
export const PRODUCTS_URL = "/product";
export const REVIEW_URL = "/review";
export const BANNER_URL = "/banner";
export const ORDERS_URL = "/user/order";
export const NEW_ORDER_URL = "/order";
export const MENU_LIST_URL = "/menu";
export const PAGES_LIST_URL = "/page";
export const PAGE_BY_SLUG_URL = "/page-by-slug";
export const CATEGORIES_LIST_URL = "/category";
export const USER_PROFILE_URL = "/profile";
export const SHIPPING_ZONE_URL = "/shipping/zone/1/method";
export const PAYMENT_LIST_URL = "/payment";
export const INNIT_MOLLIE_PAYMENT_URL = "/payment/mollie";

export const MOLLIE_PAYMENT = "mollie_wc_gateway_";
export const DIRECT_BANK_TRANSFER = "bacs";
export const FREE_SHIPPING = "free_shipping";
export const SHIPPING_ZONE_NAME = "Germany";
export const PAGE_SLUG = "post_name";
export const CATEGORY_ID = "cat_ID";
export const CATEGORY_PARENT = "parent";

export const MENU_IDS = [348, 349, 437, 438, 439];
export const MENU_TITLES = ["Über KIN", "Inspiration", "Mein Konto", "Gesetzliche Info &amp; Hilfe", "Tags"];

export const MAIL_SENT = "mail_sent";

export const COUNTRIES = ["Deutschland", "Österreich"];

// HTTP response status codes
export const HTTP_OK = 200;
export const HTTP_CREATED = 201;
export const HTTP_UNATHORIZED = 401;
export const HTTP_FORBIDDEN = 403;

// local storage
export const AUTH_TOKEN = "_token";
export const REFRESH_TOKEN = "_refresh_token";
export const USER_DATA = "_user";
export const BASKET_DATA = "_basket";
export const CURRENCY = "_currency";
export const EXPIRE_AT = "_expire_at";
export const ORDER_DATA = "_order_data";
export const USER_WAS_LOGGED_IN_DATA = "_user_was_logged_in";
export const STORE_FILTERS_DATA = "_store_filters";
export const ACTIVE_LINK_DATA = "_active_link_alles";
export const SCROLL_POSITION_DATA = "_scroll_position";
export const ITEMS_TO_RENDER_DATA = "_items_to_render";
export const SHIPPING_METHODS_DATA = "_shipping_methods";
export const PAYMENTS_METHODS_DATA = "_payments_methods";

// KIN urls
export const FACEBOOK_URL = "https://www.facebook.com/KohinoorInDeutschland";
export const INSTAGRAM_URL = "https://www.instagram.com/kohinoordeutschland/";
export const SHOP_KIN_URL = "https://shop.kohinoordeutschland.de";

// Newsletter SendinBlue
export const SENDINBLUE_CONTACTS_URL = "https://api.sendinblue.com/v3/contacts";

//  Sort and Order
export const SORT = {
  price: "price",
  title: "title",
  popularity: "popularity",
  rating: "rating",
};
export const ORDER = {
  asc: "asc",
  desc: "desc",
};

export const ANGEBOTE_FILTER = "angebote";
export const PRODUKTLINIE_ATTRIBUTE = "Produktlinie";

export const PRICE_MIN = 0;
export const PRICE_MAX = 240;

export const USER_TABS = [
  { name: "profile", ident: "profile" },
  { name: "orders", ident: "orders" },
  { name: "password-change", ident: "password" },
  { name: "logout", ident: "logout" },
];
