import { sessionStorageSetItem } from "helpers";
import {
  SET_HEADER_CATEGORY,
  SET_BACK_BUTTON_PATH,
  SET_PAGE_PARAMS,
  SET_IS_PAGE_EXPANDED,
  SET_IS_LOADING,
  SET_TOAST,
  UNSET_TOAST,
  SET_ELEMENT_SIZES_HEADER,
  SET_ELEMENT_SIZES_BREADCRUMBS_WITH_SORT,
  SET_ELEMENT_SIZES_BREADCRUMBS,
  SET_HEADER_ANIMATE_ONLY_ONCE,
  SET_IS_HEADER_MENU_OPEN,
  SET_PRODUCT_PATHNAMES,
  SET_PRODUCT_LINES,
  SET_PRODUCT_LINE,
  SET_PRICE,
  SET_AVAILABILITY,
  SET_OFFER,
  RESET_ALL_FILTERS,
  SET_SORT_FILTERS,
  SET_SELECTED_PRODUCT_LINES,
  SET_CATEGORY,
  UNSET_CATEGORY,
  SET_PRICE_MIN,
  SET_PRICE_MAX,
  SET_IS_DIRTY,
  SET_IS_LEAVE_DIALOG_ENABLED,
} from "../types";
import { HEADER_CATEGORY, HOMEPAGE_URL, PRICE_MAX, PRICE_MIN, STORE_FILTERS_DATA } from "data/constants";

export const initialState = {
  headerCategory: HEADER_CATEGORY,
  isHeaderMenuOpen: false,
  isSearchExpanded: false,
  headerAnimateOnlyOnce: false,
  backButtonPath: HOMEPAGE_URL,
  pageParams: null,
  isLoading: false,
  toast: {
    isShowed: false,
    type: "",
    message: "",
  },
  elementSizes: {
    header: null,
    breadcrumbsWithSort: null,
    breadcrumbs: null,
  },
  productPathNames: null,
  filters: {
    productLines: [],
    selectedProductLines: [],
    price: [PRICE_MIN, PRICE_MAX],
    availability: false,
    offer: false,
    sort: {
      order: "",
      orderby: "",
    },
  },
  category: null,
  isDirty: false,
  isLeaveDialogEnabled: false,
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_HEADER_CATEGORY:
      return {
        ...state,
        headerCategory: action.payload,
      };

    case SET_BACK_BUTTON_PATH:
      return {
        ...state,
        backButtonPath: action.payload,
      };

    case SET_PAGE_PARAMS:
      return {
        ...state,
        pageParams: action.payload,
      };

    case SET_IS_PAGE_EXPANDED:
      return {
        ...state,
        isSearchExpanded: action.payload,
      };

    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case SET_TOAST:
      return {
        ...state,
        toast: action.payload,
      };

    case UNSET_TOAST:
      return {
        ...state,
        toast: { isShowed: false, type: state.toast.type, message: state.toast.message },
      };

    case SET_ELEMENT_SIZES_HEADER:
      return {
        ...state,
        elementSizes: {
          ...state.elementSizes,
          header: action.payload,
        },
      };

    case SET_ELEMENT_SIZES_BREADCRUMBS_WITH_SORT:
      return {
        ...state,
        elementSizes: {
          ...state.elementSizes,
          breadcrumbsWithSort: action.payload,
        },
      };

    case SET_ELEMENT_SIZES_BREADCRUMBS:
      return {
        ...state,
        elementSizes: {
          ...state.elementSizes,
          breadcrumbs: action.payload,
        },
      };

    case SET_HEADER_ANIMATE_ONLY_ONCE:
      return {
        ...state,
        headerAnimateOnlyOnce: action.payload,
      };

    case SET_SORT_FILTERS: {
      const filters = {
        ...state.filters,
        sort: {
          ...state.filters.sort,
          ...action.payload,
        },
      };

      sessionStorageSetItem(STORE_FILTERS_DATA, filters);

      return {
        ...state,
        filters,
      };
    }

    case SET_PRODUCT_LINES: {
      const filters = {
        ...state.filters,
        productLines: action.payload,
      };

      sessionStorageSetItem(STORE_FILTERS_DATA, filters);

      return {
        ...state,
        filters,
      };
    }

    case SET_PRODUCT_LINE: {
      const isInArr = state.filters.selectedProductLines.indexOf(action.payload) > -1;
      let selectedProductLines = [...state.filters.selectedProductLines];

      if (isInArr) {
        selectedProductLines = selectedProductLines.filter((item) => item !== action.payload);
      } else {
        selectedProductLines.push(action.payload);
      }

      const filters = {
        ...state.filters,
        selectedProductLines,
      };

      sessionStorageSetItem(STORE_FILTERS_DATA, filters);

      return {
        ...state,
        filters,
      };
    }

    case SET_SELECTED_PRODUCT_LINES: {
      const filters = {
        ...state.filters,
        selectedProductLines: action.payload,
      };

      sessionStorageSetItem(STORE_FILTERS_DATA, filters);

      return {
        ...state,
        filters,
      };
    }

    case SET_PRICE: {
      const filters = {
        ...state.filters,
        price: action.payload,
      };

      sessionStorageSetItem(STORE_FILTERS_DATA, filters);

      return {
        ...state,
        filters,
      };
    }

    case SET_PRICE_MIN: {
      const filters = {
        ...state.filters,
        price: [action.payload, state.filters.price[1]],
      };

      sessionStorageSetItem(STORE_FILTERS_DATA, filters);

      return {
        ...state,
        filters,
      };
    }

    case SET_PRICE_MAX: {
      const filters = {
        ...state.filters,
        price: [state.filters.price[0], action.payload],
      };

      sessionStorageSetItem(STORE_FILTERS_DATA, filters);

      return {
        ...state,
        filters,
      };
    }

    case SET_AVAILABILITY: {
      const filters = {
        ...state.filters,
        availability: !state.filters.availability,
      };

      sessionStorageSetItem(STORE_FILTERS_DATA, filters);

      return {
        ...state,
        filters,
      };
    }

    case SET_OFFER: {
      const filters = {
        ...state.filters,
        offer: !state.filters.offer,
      };

      sessionStorageSetItem(STORE_FILTERS_DATA, filters);

      return {
        ...state,
        filters,
      };
    }

    case RESET_ALL_FILTERS:
      return { ...state, filters: { ...initialState.filters, productLines: state.productLines } };

    case SET_IS_HEADER_MENU_OPEN:
      return {
        ...state,
        isHeaderMenuOpen: !state.isHeaderMenuOpen,
      };

    case SET_PRODUCT_PATHNAMES:
      return {
        ...state,
        productPathNames: action.payload,
      };

    case SET_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };

    case UNSET_CATEGORY:
      return {
        ...state,
        category: null,
      };

    case SET_IS_DIRTY:
      return {
        ...state,
        isDirty: action.payload,
      };

    case SET_IS_LEAVE_DIALOG_ENABLED:
      return {
        ...state,
        isLeaveDialogEnabled: action.payload,
      };

    default:
      return state;
  }
}
