import { SQUARE_BRACKETS_REGEX } from "helpers";

export const createLinkPath = (asPath) => asPath.replace(SQUARE_BRACKETS_REGEX, "").split("/");

export const generateProductPathname = async (pathArray, productPaths) => {
  // Remove /product and name slug
  pathArray.pop();
  pathArray.pop();
  // Add categories paths
  productPaths.forEach(({ name, root, slugPath }) =>
    pathArray.push({ breadcrumb: name, href: `/${root}/${slugPath}` })
  );
};

export const createBreadcrumbName = (pathname, isRootCategoryMain) =>
  pathname === "product"
    ? "products"
    : isRootCategoryMain
    ? pathname.split("-")[0].replace(/\?.*$/g, "")
    : pathname.replace(/\?.*$/g, "");

export const createBreadcrumbHref = (index, pageParams, path, linkPath) =>
  index === 1 && pageParams
    ? pageParams
    : path === "product"
    ? "/category/"
    : "/" + linkPath.slice(0, index + 1).join("/");
