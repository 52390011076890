import { PRODUKTLINIE_ATTRIBUTE } from "data/constants";
// import { EMPTY_HTML_TAGS_REGEX } from "./regex";
import { isArrayDefined } from "helpers";

export const filterProductCatalogueData = (catalogueArr) =>
  catalogueArr?.map((product) => ({
    id: product.id,
    name: product.name,
    short_description: product.short_description,
    description: product.description,
    sku: product.sku,
    attributes: product.attributes,
    categories: product.categories,
    images: product.images,
    price: product.price,
    stock: product.stock_quantity,
    slug: product.slug,
    total_sales: product.total_sales,
    average_rating: product.average_rating,
  }));

/* export const filterProductData = (data) => ({
  id: data?.id,
  name: data?.name,
  slug: data?.slug,
  description: data?.description?.replace(EMPTY_HTML_TAGS_REGEX, ""),
  short_description: data?.short_description?.replace(EMPTY_HTML_TAGS_REGEX, ""),
  yoast_head_json: data?.yoast_head_json ?? "",
  images: data?.images,
  price: data?.price,
  regular_price: data?.regular_price,
  rating_count: data?.rating_count,
  average_rating: data?.average_rating,
  stock_quantity: data?.stock_quantity,
  dimensions: data?.dimensions,
  attributes: data?.attributes,
  categories: data?.categories,
  related_ids: data?.related_ids,
}); */

export const findRelatedProducts = ({ product, productsData, pathnames }) => {
  let similiarProducts = [];
  if (isArrayDefined(product.related_ids)) {
    similiarProducts = productsData.filter(({ id }) => product.related_ids.includes(id));
  } else if (pathnames.at(-1)?.id) {
    similiarProducts = productsData.filter(({ categories }) =>
      categories.find((category) => category.id === pathnames.at(-1)?.id)
    );
  }
  if (similiarProducts.length > 4) {
    similiarProducts = similiarProducts.slice(0, 3);
  }
  return similiarProducts;
};

export const filterProductLineAttr = (products) => [
  ...new Set(
    products
      .map(({ attributes }) => attributes)
      .filter((item) => item.find(({ name }) => name === PRODUKTLINIE_ATTRIBUTE))
      .map((item) =>
        item.filter((item2) => item2.name === PRODUKTLINIE_ATTRIBUTE)[0].options.map((item) => item)
      )
      .flat()
  ),
];

export const filterProductBulletPoints = (bulletPoints) =>
  bulletPoints.options.map((item) => item.split(/;/g).map((splitted) => splitted?.trimStart())).flat();

export const searchProducts = (products, searchValue) => {
  const productsWithFoundProp = products.map((item) => {
    const { name, sku, description, categories } = item;

    const toLowerCase = (string) => string.toLowerCase();
    const isIncludes = (property) => property.includes(searchValue);

    const containName = isIncludes(toLowerCase(name));
    const containCategory = categories.find(({ name: categoryName }) =>
      isIncludes(toLowerCase(categoryName))
    );
    const containSku = isIncludes(sku);
    const containDescription = isIncludes(toLowerCase(description));

    const foundIn = containName
      ? "name"
      : containCategory
      ? "category"
      : containSku
      ? "sku"
      : containDescription
      ? "description"
      : null;
    return { ...item, foundIn: foundIn };
  });
  return productsWithFoundProp.filter((item) => item.foundIn !== null);
};

export const filterProductsTags = ({ products, tag }) =>
  products.filter(({ tags }) => tags.find(({ slug }) => slug.includes(tag)));
