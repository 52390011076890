import { USER_DATA, REFRESH_TOKEN, AUTH_TOKEN, EXPIRE_AT } from "data/constants";
// Local Storage
export const checkLocalStorage = () => typeof localStorage !== "undefined";
export const localStorageGetItem = (name) =>
  localStorage.getItem(name) ? JSON.parse(localStorage.getItem(name)) : null;
export const localStorageSetItem = (name, data) => localStorage.setItem(name, JSON.stringify(data));
export const localStorageRemoveItem = (name) => localStorage.removeItem(name);
// Session Storage
export const checkSessionStorage = () => typeof sessionStorage !== "undefined";
export const sessionStorageSetItem = (name, data) => sessionStorage.setItem(name, JSON.stringify(data));
export const sessionStorageGetItem = (name) =>
  sessionStorage.getItem(name) ? JSON.parse(sessionStorage.getItem(name)) : null;
export const sessionStorageRemoveitem = (name) => sessionStorage.removeItem(name);

export const removeStoredData = () => {
  localStorageRemoveItem(USER_DATA);
  localStorageRemoveItem(REFRESH_TOKEN);
  sessionStorageRemoveitem(AUTH_TOKEN);
  localStorageRemoveItem(EXPIRE_AT);
};
