import {
  ADD_TO_CART,
  SET_CART,
  DELETE_CART,
  REMOVE_ITEM_FROM_CART,
  CHANGE_CART_ITEM_AMOUNT,
  SET_USER,
  LOGOUT_USER,
  SET_USER_ADDRESSESS,
  SET_USER_LOGIN,
  SET_USER_IS_INVOICE_SAME_AS_DELIVERY,
  SET_USER_ORDER_DELIVERY,
  SET_USER_ORDER_PAYMENT,
  SET_ORDER_FREE_LIMIT,
  SET_USER_ORDER,
  SET_USER_NEWSLETTER,
} from "../types";

export const addToCart = (product) => ({ type: ADD_TO_CART, payload: product });

export const setCart = (product) => ({ type: SET_CART, payload: product });

export const deleteCart = () => ({ type: DELETE_CART });

export const removeItemFromCart = (id) => ({ type: REMOVE_ITEM_FROM_CART, payload: id });

export const changeCartItemAmount = ({ id, amount, type }) => ({
  type: CHANGE_CART_ITEM_AMOUNT,
  payload: { id, amount, type },
});

export const setUserLogin = (userData) => ({ type: SET_USER_LOGIN, payload: userData });

export const setUser = (userData) => ({ type: SET_USER, payload: userData });

export const logOutUser = (isUserAction = false) => ({ type: LOGOUT_USER, payload: isUserAction });

export const setUserAddresses = (userAddressess) => ({ type: SET_USER_ADDRESSESS, payload: userAddressess });

export const setUserIsInvoiceSameAsDelivery = (isSame) => ({
  type: SET_USER_IS_INVOICE_SAME_AS_DELIVERY,
  payload: isSame,
});

export const setUserOrderDelivery = (delivery) => ({
  type: SET_USER_ORDER_DELIVERY,
  payload: delivery,
});

export const setUserOrderPayment = (payment) => ({
  type: SET_USER_ORDER_PAYMENT,
  payload: payment,
});

export const setOrderMinFreeLimit = (freeLimit) => ({
  type: SET_ORDER_FREE_LIMIT,
  payload: freeLimit,
});

export const setUserOrder = (payment) => ({
  type: SET_USER_ORDER,
  payload: payment,
});

export const setUserNewsletter = (agreed) => ({
  type: SET_USER_NEWSLETTER,
  payload: agreed,
});
