import { getMolliePayment } from "./mollie";

export const createCustomerData = (data) => ({
  data: {
    username: data.name,
    email: data.email,
    password: data.password,
  },
});

export const createLoginData = (data) => ({
  data: {
    email: data.email,
    password: data.password,
  },
});

export const createLostPasswordData = (data) => ({
  data: {
    email: data.email,
  },
});

export const createChangePasswordData = (data) => ({
  data: {
    password: data.old_password,
    new_password: data.password,
  },
});

export const createContactFormData = (data) => ({
  data: {
    "first-name": data.firstname,
    "last-name": data.lastname,
    phone: data.phone,
    email: data.email.toLowerCase(),
    message: data.text,
  },
});

export const createReviewData = (data, rating) => ({
  review: data.review,
  reviewer: data.reviewer,
  reviewer_email: data.reviewer_email,
  ...(rating && { rating }),
});

export const createProfileData = (data, shippingAddress) => ({
  data: {
    first_name: data.firstname,
    last_name: data.surname,
    email: data.email,
    billing_address: {
      first_name: data.firstname,
      last_name: data.surname,
      company: data.company,
      address_1: data.street,
      city: data.city,
      postcode: data.zip,
      country: data.country,
      phone: data.phone,
      email: data.email,
    },
    shipping_address: {
      first_name: shippingAddress.first_name ?? "",
      last_name: shippingAddress.last_name ?? "",
      company: shippingAddress.company ?? "",
      address_1: shippingAddress.address_1 ?? "",
      city: shippingAddress.city ?? "",
      postcode: shippingAddress.postcode ?? "",
      country: shippingAddress.country ?? "",
      phone: shippingAddress.phone ?? "",
      email: shippingAddress.email ?? "",
    },
  },
});

export const createBillingData = (data) => ({
  first_name: data.firstname,
  last_name: data.surname,
  company: data.company,
  address_1: data.street,
  city: data.city,
  postcode: data.zip,
  country: data.country,
  phone: data.phone,
  email: data.email,
});

export const createShippingData = (data) => ({
  first_name: data.delivery_firstname ?? "",
  last_name: data.delivery_surname ?? "",
  company: data.delivery_company ?? "",
  address_1: data.delivery_street ?? "",
  city: data.delivery_city ?? "",
  postcode: data.delivery_zip ?? "",
  country: data.delivery_country ?? "",
  phone: data.delivery_phone ?? "",
  email: data.delivery_email ?? "",
});

export const resetInvoiceAddress = (invoiceAddress) => ({
  email: invoiceAddress.email,
  firstname: invoiceAddress.first_name,
  surname: invoiceAddress.last_name,
  company: invoiceAddress.company,
  street: invoiceAddress.address_1 || invoiceAddress.address_2,
  zip: invoiceAddress.postcode,
  city: invoiceAddress.city,
  country: invoiceAddress.country,
  phone: invoiceAddress.phone,
});

export const resetInvoiceAndDeliveryAddress = (invoiceAddress, deliveryAddress) => ({
  email: invoiceAddress.email,
  firstname: invoiceAddress.first_name,
  surname: invoiceAddress.last_name,
  company: invoiceAddress.company,
  street: invoiceAddress.address_1 || invoiceAddress.address_2,
  zip: invoiceAddress.postcode,
  city: invoiceAddress.city,
  country: invoiceAddress.country,
  phone: invoiceAddress.phone,
  delivery_email: deliveryAddress.email,
  delivery_firstname: deliveryAddress.first_name,
  delivery_surname: deliveryAddress.last_name,
  delivery_company: deliveryAddress.company,
  delivery_street: deliveryAddress.address_1 || deliveryAddress.address_2,
  delivery_zip: deliveryAddress.postcode,
  delivery_city: deliveryAddress.city,
  delivery_country: deliveryAddress.country,
  delivery_phone: deliveryAddress.phone,
});

export const createOrderData = ({ payment, billingAddress, shippingAddress, products, shipping, note }) => ({
  data: {
    payment_method: getMolliePayment(payment.id),
    payment_method_title: payment.method_title,
    set_paid: true,
    billing: {
      first_name: billingAddress.first_name,
      last_name: billingAddress.last_name,
      address_1: billingAddress.address_1,
      address_2: billingAddress.address_2,
      city: billingAddress.city,
      state: billingAddress.state,
      postcode: billingAddress.postcode,
      country: billingAddress.country,
      email: billingAddress.email,
      phone: billingAddress.phone,
    },
    shipping: {
      first_name: shippingAddress.first_name,
      last_name: shippingAddress.last_name,
      address_1: shippingAddress.address_1,
      address_2: shippingAddress.address_2,
      city: shippingAddress.city,
      state: shippingAddress.state,
      postcode: shippingAddress.postcode,
      country: shippingAddress.country,
    },
    line_items: products.map((product) => ({ product_id: product.id, quantity: product.amount })),
    shipping_lines: [
      {
        method_id: shipping.id,
        method_title: shipping.title,
        total: shipping.settings.cost ? shipping.settings.cost : 0,
      },
    ],
    ...(note && {
      meta_data: [
        {
          key: "customer_note",
          value: note,
        },
      ],
    }),
  },
});

export const createMolliePaymentData = (order) => ({
  data: {
    order_id: order.id,
    description: "Testing order",
    amount: {
      currency: order.currency,
      value: order.total,
    },
    method: order.payment_method,
    locale: "de_DE",
  },
});

export const createAddressData = (data, shipping) => ({
  billing_address: {
    first_name: data.firstname,
    last_name: data.surname,
    company: data.company,
    address_1: data.street,
    city: data.city,
    postcode: data.zip,
    country: data.country,
    phone: data.phone,
    email: data.email,
  },
  shipping_address: {
    first_name: shipping.first_name ?? "",
    last_name: shipping.last_name ?? "",
    company: shipping.last_name ?? "",
    address_1: shipping.address_1 ?? "",
    city: shipping.city ?? "",
    postcode: shipping.postcode ?? "",
    country: shipping.country ?? "",
    phone: shipping.phone ?? "",
    email: shipping.email ?? "",
  },
});
