import { PAGE_SLUG } from "data/constants";

/* export const filterPageData = (pageData) => ({
  id: pageData?.["ID"] ?? "",
  post_name: pageData?.post_name ?? "",
  post_title: pageData?.post_title ?? "",
  post_content: pageData?.post_content ?? "",
}); */

export const createPagesPaths = ({ menu, dirName, pages }) => {
  const menuRoutes = menu
    .find((item) => item.find((item2) => item2.url.includes(`/${dirName}`)))
    .map(({ url }) => url.replace(dirName, "").replaceAll("/", ""));

  const filteredPages = pages.filter((item) => menuRoutes.some((route) => route === item[PAGE_SLUG]));
  return filteredPages;
};
