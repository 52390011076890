import { createStore } from "redux";
import { ISSERVER, sessionStorageGetItem } from "helpers";
import { STORE_FILTERS_DATA } from "data/constants";
import { initialState } from "./appReducer";
import AppReducer from "./appReducer";

const loadFromSessionStorage = () => {
  try {
    const serializedFiltersStore = sessionStorageGetItem(STORE_FILTERS_DATA);
    if (serializedFiltersStore === null) return initialState;
    initialState.filters = serializedFiltersStore;
    return initialState;
  } catch (e) {
    console.error(e);
    return undefined;
  }
};

const persistedState = !ISSERVER ? loadFromSessionStorage() : initialState;

const store = createStore(AppReducer, persistedState);

export default store;
