const isShowed = true;

export const setToastProductAddedError = () => ({
  isShowed,
  type: "error",
  message: "reached-stock-limit",
});

export const setToastLogout = () => ({
  isShowed,
  type: "error",
  message: "session-expire",
});

export const setToastFormError = (message) => ({
  isShowed,
  type: "error",
  message,
});

export const setToastFormSuccess = (message) => ({
  isShowed,
  type: "success",
  message,
});
