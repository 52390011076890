export const filterShippingZoneMethods = (data) =>
  data.map(({ settings: { min_amount, cost }, description, title, id }) => ({
    settings: { min_amount, cost },
    description,
    title,
    id,
  }));

export const filterPaymentMethods = (data) =>
  data.map(({ id, method_title, method_description, description, enabled }) => ({
    id,
    method_title,
    method_description,
    description,
    enabled,
  }));
