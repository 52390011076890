import { HEADER_CATEGORY } from "data/constants";
export * from "./banner";
export * from "./basket";
export * from "./breadcrumb";
export * from "./catalogue";
export * from "./category";
export * from "./date";
export * from "./event";
export * from "./image";
export * from "./link";
export * from "./localStorage";
export * from "./mollie";
export * from "./number";
export * from "./order";
export * from "./page";
export * from "./products";
export * from "./regex";
export * from "./user";

// Context helpers
export const isHeaderCategory = (category) => category === HEADER_CATEGORY;

// Debounce
export const debounce = (func, timeout = 1000) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};

// Rest
export const isArray = (array) => Array.isArray(array);
export const isArrayEmpty = (array) => Array.isArray(array) && array.length === 0;
export const isArrayDefined = (array) => Array.isArray(array) && array.length > 0;
export const hasOwn = (object, name) => Object.hasOwn(object, name);
export const isObjectDefined = (object) => Object.keys(object).length > 0;
export const isObjectEmpty = (object) => Object.keys(object).length === 0;
export const isFunction = (prop) => typeof prop === "function";
export const isBoolean = (prop) => typeof prop === "boolean";

export const ISSERVER = typeof window === "undefined";
