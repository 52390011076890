import axios from "axios";
import { SENDINBLUE_CONTACTS_URL, HTTP_CREATED } from "data/constants";

export const subscribeToNewsletter = ({ onSuccess, onError }) => {
  return async (data) => {
    const email = data.email?.toLowerCase();

    try {
      const response = await axios.post(
        SENDINBLUE_CONTACTS_URL,
        { updateEnabled: false, email },
        {
          headers: { "Content-Type": "application/json", "api-key": process.env.SENDINBLUE_API_KEY },
        }
      );

      if (response?.status === HTTP_CREATED && response?.data?.id) {
        onSuccess();
      }
    } catch (e) {
      onError(e);
    }
  };
};
