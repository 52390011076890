// core / app
export const SET_HEADER_CATEGORY = "SET_HEADER_CATEGORY";
export const SET_BACK_BUTTON_PATH = "SET_BACK_BUTTON_PATH";
export const SET_PAGE_PARAMS = "SET_PAGE_PARAMS";
export const SET_IS_PAGE_EXPANDED = "SET_IS_PAGE_EXPANDED";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_TOAST = "SET_TOAST";
export const UNSET_TOAST = "UNSET_TOAST";
export const SET_ELEMENT_SIZES_HEADER = "SET_ELEMENT_SIZES_HEADER";
export const SET_ELEMENT_SIZES_BREADCRUMBS_WITH_SORT = "SET_ELEMENT_SIZES_BREADCRUMBS_WITH_SORT";
export const SET_ELEMENT_SIZES_BREADCRUMBS = "SET_ELEMENT_SIZES_BREADCRUMBS";
export const SET_HEADER_ANIMATE_ONLY_ONCE = "SET_HEADER_ANIMATE_ONLY_ONCE";
export const SET_IS_HEADER_MENU_OPEN = "SET_IS_HEADER_MENU_OPEN";
export const SET_PRODUCT_PATHNAMES = "SET_PRODUCT_PATHNAMES";
export const SET_CATEGORY = "SET_CATEGORY";
export const UNSET_CATEGORY = "UNSET_CATEGORY";
export const SET_IS_DIRTY = "SET_IS_DIRTY";
export const SET_IS_LEAVE_DIALOG_ENABLED = "SET_IS_LEAVE_DIALOG_ENABLED";
// filters
export const SET_AVAILABILITY = "SET_AVAILABILITY";
export const SET_OFFER = "SET_OFFER";
export const SET_PRICE = "SET_PRICE";
export const SET_PRICE_MIN = "SET_PRICE_MIN";
export const SET_PRICE_MAX = "SET_PRICE_MAX";
export const SET_PRODUCT_LINES = "SET_PRODUCT_LINES";
export const SET_PRODUCT_LINE = "SET_PRODUCT_LINE";
export const SET_SELECTED_PRODUCT_LINES = "SET_SELECTED_PRODUCT_LINES";
export const RESET_ALL_FILTERS = "RESET_ALL_FILTERS";
export const SET_SORT_FILTERS = "SET_SORT_FILTERS";

// eshop
export const SET_CART = "SET_CART";
export const ADD_TO_CART = "ADD_TO_CART";
export const DELETE_CART = "DELETE_CART";
export const REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART";
export const SET_USER = "SET_USER";
export const SET_USER_LOGIN = "SET_USER_LOGIN";
export const SET_USER_ADDRESSESS = "SET_USER_ADDRESSESS";
export const SET_USER_IS_INVOICE_SAME_AS_DELIVERY = "SET_USER_IS_INVOICE_SAME_AS_DELIVERY";
export const SET_USER_ORDER_DELIVERY = "SET_USER_ORDER_DELIVERY";
export const SET_USER_ORDER_PAYMENT = "SET_USER_ORDER_PAYMENT";
export const SET_USER_ORDER = "SET_USER_ORDER";
export const SET_USER_NEWSLETTER = "SET_USER_NEWSLETTER";
export const SET_ORDER_FREE_LIMIT = "SET_ORDER_FREE_LIMIT";
export const CHANGE_CART_ITEM_AMOUNT = "CHANGE_CART_ITEM_AMOUNT";
export const LOGIN_USER = "LOGIN_USER";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const SET_IS_LOADED = "SET_IS_LOADED";
export const LOGOUT_USER = "LOGOUT_USER";
