import React, { useReducer, useEffect } from "react";
import { checkLocalStorage, isObjectDefined, localStorageGetItem } from "helpers";
import { BASKET_DATA, ORDER_DATA, USER_DATA } from "data/constants";
import { setCart, setUser, setUserOrder } from "./eshopActions";
import EshopContext from "./eshopContext";
import EshopReducer from "./eshopReducer";
import { SET_IS_LOADED } from "context/types";

export const initialState = {
  // Cart
  cart: [],
  cartItemsTotal: 0,
  cartSumTotal: 0,
  // Order
  order: {
    payment: null,
    delivery: null,
    minFreeLimit: null,
  },
  // Currency
  currency: "DE",
  // User
  user: {
    userInfo: {
      name: "",
    },
    isLoggedIn: false,
    newsletter: false,
    storedInvoiceAddress: false,
    storedDeliveryAddress: false,
    isInvoiceSameAsDelivery: true,
  },
  isLoaded: false,
};

export const eshopStore = {
  isReady: false,
  dispatch: () => {
    console.error("store is NOT ready");
  },
};

export const userIsLogged = { isLoggedIn: false };

const EshopState = ({ children }) => {
  const localStateCart = checkLocalStorage() ? localStorageGetItem(BASKET_DATA) : null;
  const localStateUser = checkLocalStorage() ? localStorageGetItem(USER_DATA) : null;
  const localStateOrder = checkLocalStorage() ? localStorageGetItem(ORDER_DATA) : null;

  const [state, dispatchEshop] = useReducer(EshopReducer, initialState);

  if (!eshopStore.isReady) {
    eshopStore.isReady = true;
    eshopStore.dispatch = (params) => dispatchEshop(params);
    Object.freeze(eshopStore);
  }

  useEffect(() => {
    if (localStateCart && localStateCart.length > 0) {
      dispatchEshop(setCart(localStateCart));
    }
    if (localStateUser && isObjectDefined(localStateUser)) {
      dispatchEshop(setUser(localStateUser));
    }
    if (localStateOrder && isObjectDefined(localStateOrder)) {
      dispatchEshop(setUserOrder(localStateOrder));
    }
    dispatchEshop({ type: SET_IS_LOADED });
    // eslint-disable-next-line
  }, []);

  return <EshopContext.Provider value={{ state, dispatchEshop }}>{children}</EshopContext.Provider>;
};

export default EshopState;
