import {
  INNIT_MOLLIE_PAYMENT_URL,
  SHIPPING_METHODS_DATA,
  PAYMENTS_METHODS_DATA,
  ORDER_COMPLETED_URL,
  SHIPPING_ZONE_URL,
  PAYMENT_LIST_URL,
  ORDERS_URL,
  NEW_ORDER_URL,
} from "data/constants";
import {
  isDirectBankPayment,
  localStorageGetItem,
  localStorageSetItem,
  isMolliePayment,
  isObjectDefined,
  isArrayDefined,
} from "helpers";
import { filterPaymentMethods, filterShippingZoneMethods } from "helpers/order";
import { createMolliePaymentData, createOrderData } from "helpers/woocommerce";
import { setToastFormError, setToastFormSuccess } from "utils/toast";
import { setIsDirty, setToast } from "context/app/appActions";
import { getWoocommerceQuery, woocommercePost } from "./";
import { deleteCart } from "context/eshop/eshopActions";

// Orders query
export const getUserOrders = async (setOrders) => {
  const { data } = await getWoocommerceQuery(ORDERS_URL);
  isArrayDefined(data) && setOrders(data);
};

export const getUserOrder = async ({ orderId, setOrder }) => {
  try {
    const { data } = await getWoocommerceQuery(`${ORDERS_URL}/${orderId}`);

    if (data && isObjectDefined(data)) {
      return setOrder(data);
    }
    return console.error(data);
  } catch (e) {
    return console.error(e);
  }
};

export const createOrder = async (orderData) => {
  const orderCreated = await woocommercePost(NEW_ORDER_URL, orderData);

  if (orderCreated?.data) {
    return orderCreated.data;
  } else {
    throw orderCreated;
  }
};

export const initMolliePayment = ({ setPaymentInterval, setPaymentLoading, router, dispatch }) => {
  return async (order) => {
    const paymentData = createMolliePaymentData(order);
    try {
      const { data, ...rest } = await woocommercePost(INNIT_MOLLIE_PAYMENT_URL, paymentData);
      if (data?.payment_id && data?.checkout_url) {
        setPaymentInterval(true);
        setPaymentLoading(true);
        setTimeout(() => router.push(data?.checkout_url), 3000);
      }
      if (rest?.message) {
        dispatch(setToast(setToastFormError(rest?.message)));
      }
    } catch (e) {
      dispatch(setToast(setToastFormError(e?.message)));
      console.error("e", e?.message);
    }
  };
};

export const submitOrder = async ({
  data,
  cart,
  payment,
  delivery,
  newsletter,
  subscribeToNewsletter,
  userInfo,
  dispatch,
  dispatchEshop,
  storedDeliveryAddress,
  storedInvoiceAddress,
  isInvoiceSameAsDelivery,
  setPaymentLoading,
  router,
  initMolliePayment,
}) => {
  if (!data.agree || !cart?.length || !payment || !delivery) return false;
  dispatch(setIsDirty(false));

  // Newsletter subscribe
  if (data.newsletter && !newsletter) {
    subscribeToNewsletter(userInfo?.email ? userInfo : storedDeliveryAddress);
  }

  // Create order
  let shippingAddress = storedDeliveryAddress;
  if (isInvoiceSameAsDelivery) shippingAddress = storedInvoiceAddress;
  const orderData = createOrderData({
    payment,
    billingAddress: storedInvoiceAddress,
    shippingAddress,
    products: cart,
    shipping: delivery,
    note: data?.note,
  });

  try {
    const orderCreated = await createOrder(orderData);

    if (orderCreated?.id) {
      dispatch(setToast(setToastFormSuccess("order-created")));
      // Select payment type
      switch (true) {
        case isDirectBankPayment(payment.id): {
          setPaymentLoading(true);
          dispatchEshop(deleteCart());
          router.push(ORDER_COMPLETED_URL);
          break;
        }

        case isMolliePayment(payment.id): {
          dispatchEshop(deleteCart());
          initMolliePayment(orderCreated);
          break;
        }

        default: {
          dispatch(setToast(setToastFormError(orderCreated?.message)));
          break;
        }
      }
    } else {
      return dispatch(setToast(setToastFormError(orderCreated?.message)));
    }
  } catch (e) {
    dispatch(setToast(setToastFormError(e?.message)));
    console.error(e);
  }
};

// Shipping Query
const getAndStoreShippingZoneMethods = async (setShippingMethods) => {
  try {
    const { data } = await getWoocommerceQuery(SHIPPING_ZONE_URL);

    if (data && isArrayDefined(data)) {
      const shippingMethods = filterShippingZoneMethods(data);

      localStorageSetItem(SHIPPING_METHODS_DATA, shippingMethods);
      return setShippingMethods(shippingMethods);
    }

    setShippingMethods(null);
  } catch (e) {
    console.error(e);
    return;
  }
};

// Payment Query
const getAndStorePaymentMethods = async (setPayments) => {
  try {
    const { data } = await getWoocommerceQuery(PAYMENT_LIST_URL);

    if (data && isArrayDefined(data)) {
      const payments = filterPaymentMethods(data);

      localStorageSetItem(PAYMENTS_METHODS_DATA, payments);
      return setPayments(payments);
    }

    setPayments(null);
  } catch (e) {
    console.error(e);
    return;
  }
};

export const getPaymentAndShipping = ({ shippingMethods, payments, setShippingMethods, setPayments }) => {
  if (!shippingMethods.length) {
    if (SHIPPING_METHODS_DATA in localStorage) {
      const storedShippingMethods = localStorageGetItem(SHIPPING_METHODS_DATA);
      isArrayDefined(storedShippingMethods) && setShippingMethods(storedShippingMethods);
    } else {
      getAndStoreShippingZoneMethods(setShippingMethods);
    }
  }

  if (!payments.length) {
    if (PAYMENTS_METHODS_DATA in localStorage) {
      const storedPaymentMethods = localStorageGetItem(PAYMENTS_METHODS_DATA);
      isArrayDefined(storedPaymentMethods) && setPayments(storedPaymentMethods);
    } else {
      getAndStorePaymentMethods(setPayments);
    }
  }
};
