import {
  SET_HEADER_CATEGORY,
  SET_BACK_BUTTON_PATH,
  SET_PAGE_PARAMS,
  SET_IS_PAGE_EXPANDED,
  SET_IS_LOADING,
  SET_TOAST,
  UNSET_TOAST,
  SET_ELEMENT_SIZES_HEADER,
  SET_ELEMENT_SIZES_BREADCRUMBS_WITH_SORT,
  SET_ELEMENT_SIZES_BREADCRUMBS,
  SET_HEADER_ANIMATE_ONLY_ONCE,
  SET_IS_HEADER_MENU_OPEN,
  SET_PRODUCT_PATHNAMES,
  SET_OFFER,
  SET_AVAILABILITY,
  RESET_ALL_FILTERS,
  SET_PRODUCT_LINES,
  SET_PRODUCT_LINE,
  SET_PRICE,
  SET_PRICE_MIN,
  SET_PRICE_MAX,
  SET_SORT_FILTERS,
  SET_SELECTED_PRODUCT_LINES,
  SET_CATEGORY,
  UNSET_CATEGORY,
  SET_IS_DIRTY,
  SET_IS_LEAVE_DIALOG_ENABLED,
} from "../types";

export const setHeaderCategory = (category) => ({ type: SET_HEADER_CATEGORY, payload: category });
export const setBackButtonPath = (pathname) => ({ type: SET_BACK_BUTTON_PATH, payload: pathname });
export const setPageParams = (params) => ({ type: SET_PAGE_PARAMS, payload: params });
export const setIsSearchExpanded = (isExpanded) => ({ type: SET_IS_PAGE_EXPANDED, payload: isExpanded });
export const setIsLoading = (isLoading) => ({ type: SET_IS_LOADING, payload: isLoading });
export const setToast = (toast) => ({ type: SET_TOAST, payload: toast });
export const unsetToast = (toast) => ({ type: UNSET_TOAST, payload: toast });
export const setElementSizesHeader = (height) => ({ type: SET_ELEMENT_SIZES_HEADER, payload: height });
export const setElementSizesBreadcrumbsWithSort = (height) => ({
  type: SET_ELEMENT_SIZES_BREADCRUMBS_WITH_SORT,
  payload: height,
});
export const setElementSizesBreadcrumbs = (height) => ({
  type: SET_ELEMENT_SIZES_BREADCRUMBS,
  payload: height,
});
export const setHeaderAnimateOnlyOnce = (isAnimated) => ({
  type: SET_HEADER_ANIMATE_ONLY_ONCE,
  payload: isAnimated,
});
export const setIsHeaderMenuOpen = () => ({
  type: SET_IS_HEADER_MENU_OPEN,
});
export const setProductPathNames = (pathnames) => ({
  type: SET_PRODUCT_PATHNAMES,
  payload: pathnames,
});
export const setCategory = (category) => ({
  type: SET_CATEGORY,
  payload: category,
});
export const unsetCategory = () => ({ type: UNSET_CATEGORY });

export const setSortFilters = (filters) => ({
  type: SET_SORT_FILTERS,
  payload: filters,
});

export const setFiltersProductLines = (state) => ({ type: SET_PRODUCT_LINES, payload: state });
export const setFiltersSelectedProductLines = (state) => ({ type: SET_PRODUCT_LINE, payload: state });
export const setFiltersSelectedProductLinesArray = (state) => ({
  type: SET_SELECTED_PRODUCT_LINES,
  payload: state,
});

export const setFiltersPrice = (price) => ({ type: SET_PRICE, payload: price });
export const setFiltersPriceMin = (minPrice) => ({ type: SET_PRICE_MIN, payload: minPrice });
export const setFiltersPriceMax = (maxPrice) => ({ type: SET_PRICE_MAX, payload: maxPrice });

export const setFiltersOffer = () => ({ type: SET_OFFER });

export const setFiltersAvailability = () => ({ type: SET_AVAILABILITY });

export const resetAllFilters = () => ({ type: RESET_ALL_FILTERS });

export const setIsDirty = (isDirty) => ({ type: SET_IS_DIRTY, payload: isDirty });
export const setIsLeaveDialogEnabled = (isEnabled) => ({
  type: SET_IS_LEAVE_DIALOG_ENABLED,
  payload: isEnabled,
});
