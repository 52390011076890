import { setToastFormError, setToastFormSuccess } from "utils/toast";
import { PER_PAGE, PRODUCTS_URL, REVIEW_URL } from "data/constants";
import { getWoocommerceQuery, woocommercePost } from "./";
import { createReviewData } from "helpers/woocommerce";
import { filterProductCatalogueData } from "helpers";
import { setToast } from "context/app/appActions";

// Products Query
export const woocommerceGetProducts = async ({ query, categoryId = null, relatedIds = null }) => {
  const { data, meta } = await getWoocommerceQuery(PRODUCTS_URL, {
    per_page: query?.perPage ? query.perPage : PER_PAGE,
    ...(query?.orderby && { orderby: query.orderby }),
    ...(categoryId && { category: categoryId }),
    ...(relatedIds && { include: relatedIds }),
  });

  const total = meta?.pagination?.records_count || 0;
  const productsData = data?.length ? filterProductCatalogueData(data) : [];

  return {
    total,
    products: productsData,
  };
};

// Review
export const createProductReview = async ({ slug, data, isLoggedIn, userInfo, rating, dispatch }) => {
  if (isLoggedIn) {
    data.reviewer = userInfo.name;
    data.reviewer_email = userInfo.email;
  }

  const reviewData = createReviewData(data, rating);

  try {
    const newReview = await woocommercePost(`${PRODUCTS_URL}/${slug}/${REVIEW_URL}`, reviewData);

    if (newReview?.data?.id) {
      return dispatch(setToast(setToastFormSuccess("review-success")));
    }

    if (newReview?.response?.data?.message) {
      dispatch(setToast(setToastFormError(newReview?.response?.data?.message)));
    }
  } catch (e) {
    dispatch(setToast(setToastFormError(e?.response?.data?.error?.message)));
  }
};
