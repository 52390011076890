import { setToastFormError, setToastFormSuccess } from "utils/toast";
import { CONTACT_FORM_URL, MAIL_SENT } from "data/constants";
import { createContactFormData } from "helpers/woocommerce";
import { setToast } from "context/app/appActions";
import axios from "utils/axios";

export const sendContactForm = async ({ data, dispatch }) => {
  const formData = createContactFormData(data);

  try {
    const {
      data: { data, error },
    } = await axios.post(process.env.WOOCOMMERCE_URL + CONTACT_FORM_URL, formData);

    if (data?.status === MAIL_SENT) {
      return dispatch(setToast(setToastFormSuccess(data?.message)));
    }

    if (error) {
      const errorMsg =
        error?.meta?.[0]?.message && error?.meta?.[0]?.field
          ? `${error?.meta?.[0]?.field}: ${error?.meta?.[0]?.message}`
          : error?.message;

      dispatch(setToast(setToastFormError(errorMsg)));
    }
  } catch (e) {
    console.error(e);
    dispatch(setToast(setToastFormError(e?.response?.data?.error?.message)));
  }
};
