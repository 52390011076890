import Router from "next/router";
import {
  AUTH_TOKEN,
  HTTP_CREATED,
  REFRESH_TOKEN,
  REFRESH_TOKEN_URL,
  LOGOUT_URL,
  HTTP_OK,
  HOMEPAGE_URL,
  USER_WAS_LOGGED_IN_DATA,
} from "data/constants";
import { localStorageGetItem, localStorageSetItem, removeStoredData, sessionStorageSetItem } from "helpers";
import { setIsLoading, setToast } from "context/app/appActions";
import { logOutUser } from "context/eshop/eshopActions";
import { eshopStore } from "context/eshop/EshopState";
import { setToastFormSuccess } from "utils/toast";
import store from "context/app/appContext";
import { woocommercePost } from "./";
import { isFunction } from "helpers";
import axios from "../axios";

// Refresh token

export const removeDataAndRedirect = () => {
  removeStoredData();
  store.dispatch(setIsLoading(false));
  Router.push(HOMEPAGE_URL, null, { shallow: true });
};

export const refreshTokenFunc = async ({ setIsRefreshInProcess, signal }) => {
  const refreshTokenData = {
    data: {
      refreshToken: localStorageGetItem(REFRESH_TOKEN),
    },
  };

  isFunction(setIsRefreshInProcess) && setIsRefreshInProcess(true);

  const data = await woocommercePost(REFRESH_TOKEN_URL, refreshTokenData, signal);

  if (data?.data?.code === HTTP_CREATED) {
    sessionStorageSetItem(AUTH_TOKEN, data.data.accessToken);
    localStorageSetItem(REFRESH_TOKEN, data.data.refreshToken);
    axios.defaults.headers.common["Authorization"] = "Bearer " + data.data.accessToken;
    isFunction(setIsRefreshInProcess) && setIsRefreshInProcess(false);
  } else {
    isFunction(setIsRefreshInProcess) && setIsRefreshInProcess(false);
    eshopStore.dispatch(logOutUser());
  }
};

// Logout

export const logOut = async (setUserWasLoggedIn = false, isUserAction) => {
  setUserWasLoggedIn && localStorageSetItem(USER_WAS_LOGGED_IN_DATA, true);

  try {
    const response = await axios.post(process.env.WOOCOMMERCE_URL + LOGOUT_URL);
    store.dispatch(setIsLoading(true));

    if (response?.data?.data?.code === HTTP_OK) {
      removeDataAndRedirect(true);
      isUserAction
        ? store.dispatch(setToast(setToastFormSuccess("successfull-logout")))
        : localStorageSetItem(USER_WAS_LOGGED_IN_DATA, true);
    }
  } catch (e) {
    !setUserWasLoggedIn && localStorageSetItem(USER_WAS_LOGGED_IN_DATA, true);
    removeDataAndRedirect();
    console.error(e);
    return;
  }
};
