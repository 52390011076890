import { SORT, ORDER, ANGEBOTE_FILTER, PRODUKTLINIE_ATTRIBUTE } from "data/constants";
import { filterProductLineAttr, isArray, isArrayDefined } from "./";

// SEO
export const generateSeo = (yoast_head_json, imageSrc) => ({
  title: yoast_head_json?.title ?? "",
  description: yoast_head_json?.og_description ?? "",
  image: imageSrc ?? "",
  og_title: yoast_head_json?.og_title ?? "",
  og_url: yoast_head_json?.og_url ?? "",
  og_locale: yoast_head_json?.og_locale ?? "",
  og_type: yoast_head_json?.og_type ?? "",
  og_site_name: yoast_head_json?.og_site_name ?? "",
});

export const createSeoTitle = (pathname) =>
  pathname
    ? isArray(pathname)
      ? pathname.join("-")
      : pathname?.slice(0, 1)?.toUpperCase() + pathname?.slice(1)
    : "";

// Products Sort and Filter

const sortProducts = (products, orderBy, order) => {
  if (orderBy === SORT.price) {
    return products.sort((a, b) =>
      order === ORDER.asc ? Number(a.price) - Number(b.price) : Number(b.price) - Number(a.price)
    );
  }

  if (orderBy === SORT.title) {
    return products.sort((a, b) =>
      order === ORDER.asc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
    );
  }

  if (orderBy === SORT.popularity) {
    return products.sort((a, b) =>
      order === ORDER.asc ? a.total_sales - b.total_sales : b.total_sales - a.total_sales
    );
  }

  if (orderBy === SORT.rating) {
    return products.sort((a, b) =>
      order === ORDER.asc ? a.average_rating - b.average_rating : b.average_rating - a.average_rating
    );
  }
};

const filterProductsPrice = (products, min, max) => {
  if (min && !max) {
    return products.filter(({ price }) => Number(price) >= min);
  }

  if (!min && max) {
    return products.filter(({ price }) => Number(price) <= max);
  }

  if (min && max) {
    return products.filter(({ price }) => Number(price) >= min && Number(price) <= max);
  }
};

const filterProductsStock = (products) => {
  return products.filter(({ stock }) => stock > 0);
};

const filterProductsLine = (products, productLine) => {
  return products.filter((product) => {
    const foundProductLinie = product.attributes.find(({ name }) => name === PRODUKTLINIE_ATTRIBUTE);
    const hasProductAttribute = foundProductLinie
      ? !!foundProductLinie.options.find((item) => productLine.includes(item))
      : null;

    if (hasProductAttribute) return product;
    return null;
  });
};

const filterProductsOffer = (products, slug) => {
  return products.filter((product) => {
    const { categories } = product;
    const isProductInOffer = categories.find((category) => category.slug === slug);
    return isProductInOffer ? product : null;
  });
};

export const selectFilter = (filters, products) => {
  let productsArr = [...products];

  if (filters?.sort?.orderby && filters?.sort?.order) {
    productsArr = sortProducts(productsArr, filters.sort.orderby, filters.sort.order);
  }

  if (isArray(filters?.price)) {
    productsArr = filterProductsPrice(productsArr, filters?.price[0], filters?.price[1]);
  }

  if (filters?.offer) {
    productsArr = filterProductsOffer(productsArr, ANGEBOTE_FILTER);
  }

  if (filters?.availability) {
    productsArr = filterProductsStock(productsArr);
  }

  if (isArrayDefined(filters?.selectedProductLines)) {
    productsArr = filterProductsLine(productsArr, filters.selectedProductLines);
  }

  return productsArr;
};

export const createFiltersState = ({ filters, products }) => {
  // add product lines
  const attrs = filterProductLineAttr(products);
  const isSame = !(attrs.length > 0) && filters.productLines.every((filter) => attrs.includes(filter));
  isSame ? null : (filters.productLines = attrs);

  return filters;
};

export const setSortCss = ({ sort, setCss }) => {
  switch (true) {
    case ORDER.asc === sort.order && SORT.price === sort.orderby:
      return setCss(1);

    case ORDER.desc === sort.order && SORT.price === sort.orderby:
      return setCss(2);

    case SORT.title === sort.orderby:
      return setCss(3);

    case SORT.popularity === sort.orderby:
      return setCss(4);

    case SORT.rating === sort.orderby:
      return setCss(5);

    default:
      return setCss(0);
  }
};
