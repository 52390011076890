import { HTTP_OK } from "data/constants";
import axios from "../axios";
export * from "./session";
export * from "./product";
export * from "./contact";
export * from "./order";
export * from "./user";

// GET Query
export async function getWoocommerceQuery(url = "", params = {}) {
  try {
    const { status, data: responseData } = await axios.get(process.env.WOOCOMMERCE_URL + url, {
      params: { ...params },
    });

    if (status === HTTP_OK && typeof responseData?.errors === "undefined") {
      const { data, meta } = responseData;

      const hasMeta = meta?.pagination && { meta };

      return {
        data,
        ...hasMeta,
      };
    } else {
      return [];
    }
  } catch (error) {
    return error;
  }
}

// POST Query
export const woocommercePost = async (url, data, signal) => {
  try {
    const response = await axios.post(process.env.WOOCOMMERCE_URL + url, data, signal && { signal });
    if (response?.data) return response?.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};
