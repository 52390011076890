import {
  SET_USER_IS_INVOICE_SAME_AS_DELIVERY,
  CHANGE_CART_ITEM_AMOUNT,
  SET_USER_ORDER_DELIVERY,
  SET_USER_ORDER_PAYMENT,
  REMOVE_ITEM_FROM_CART,
  SET_ORDER_FREE_LIMIT,
  SET_USER_ADDRESSESS,
  SET_USER_NEWSLETTER,
  SET_USER_LOGIN,
  SET_USER_ORDER,
  ADD_TO_CART,
  DELETE_CART,
  LOGOUT_USER,
  SET_USER,
  SET_CART,
  SET_IS_LOADED,
} from "../types";
import {
  localStorageRemoveItem,
  localStorageSetItem,
  createUserInfoData,
  getCartItemsTotal,
  getCartSumTotal,
  increaseAmount,
  decreseAmount,
} from "helpers";
import { BASKET_DATA, ORDER_DATA, USER_DATA } from "data/constants";
import { initialState, userIsLogged } from "./EshopState";
import { logOut } from "utils";

const reducer = (state, action) => {
  const { payload } = action;

  switch (action.type) {
    case SET_CART: {
      const cart = payload;
      const cartItemsTotal = getCartItemsTotal(cart);
      const cartSumTotal = getCartSumTotal(cart);

      return {
        ...state,
        cart,
        cartItemsTotal,
        cartSumTotal,
      };
    }

    case ADD_TO_CART: {
      const cart = [...state.cart];
      const isProductInBasket = cart.find(({ id }) => id === payload.id);

      if (isProductInBasket) {
        const indexOfProduct = cart.map(({ id }) => id).indexOf(payload.id);
        cart[indexOfProduct].amount = cart[indexOfProduct].amount + payload.amount;
      } else {
        cart.push(payload);
      }

      localStorageSetItem(BASKET_DATA, cart);

      const cartItemsTotal = getCartItemsTotal(cart);
      const cartSumTotal = getCartSumTotal(cart);

      return {
        ...state,
        cart,
        cartItemsTotal,
        cartSumTotal,
      };
    }

    case DELETE_CART: {
      localStorageRemoveItem(BASKET_DATA);

      return {
        ...state,
        cart: [],
        cartItemsTotal: 0,
        cartSumTotal: 0,
      };
    }

    case REMOVE_ITEM_FROM_CART: {
      const cart = [...state.cart].filter(({ id }) => id !== payload);
      const cartItemsTotal = getCartItemsTotal(cart);
      const cartSumTotal = getCartSumTotal(cart);

      localStorageSetItem(BASKET_DATA, cart);

      return {
        ...state,
        cart,
        cartItemsTotal,
        cartSumTotal,
      };
    }

    case CHANGE_CART_ITEM_AMOUNT: {
      const cart = [...state.cart];
      const itemIndex = cart.map(({ id }) => id).indexOf(payload.id);
      const item = cart[itemIndex];

      if (payload.type === "ADD") {
        item.amount = increaseAmount(payload.amount, item.stock);
      }
      if (payload.type === "REMOVE") {
        item.amount = decreseAmount(payload.amount);
      }
      if (payload.type === "SET") {
        item.amount = payload.amount;
      }

      cart[itemIndex] = item;

      const cartItemsTotal = getCartItemsTotal(cart);
      const cartSumTotal = getCartSumTotal(cart);

      localStorageSetItem(BASKET_DATA, cart);

      return {
        ...state,
        cart,
        cartItemsTotal,
        cartSumTotal,
      };
    }

    case SET_USER_LOGIN: {
      const user = {
        ...state.user,
        userInfo: createUserInfoData(payload),
        isLoggedIn: true,
      };

      userIsLogged.isLoggedIn = true;

      localStorageSetItem(USER_DATA, user);

      return {
        ...state,
        user,
      };
    }

    case SET_USER_NEWSLETTER: {
      const user = {
        ...state.user,
        newsletter: true,
      };

      localStorageSetItem(USER_DATA, user);

      return {
        ...state,
        user,
      };
    }

    case SET_USER_ADDRESSESS: {
      const { billing_address, shipping_address } = payload;

      const userData = {
        ...state.user,
        storedInvoiceAddress: billing_address,
        storedDeliveryAddress: shipping_address,
      };

      localStorageSetItem(USER_DATA, userData);

      return {
        ...state,
        user: userData,
      };
    }

    case SET_USER_IS_INVOICE_SAME_AS_DELIVERY: {
      const userData = {
        ...state.user,
        isInvoiceSameAsDelivery: payload,
      };
      localStorageSetItem(USER_DATA, userData);

      return {
        ...state,
        user: userData,
      };
    }

    case SET_USER_ORDER_DELIVERY: {
      const order = {
        ...state.order,
        delivery: payload,
      };

      localStorageSetItem(ORDER_DATA, order);

      return {
        ...state,
        order,
      };
    }

    case SET_USER_ORDER_PAYMENT: {
      const payment = payload;
      const order = {
        ...state.order,
        payment,
      };

      localStorageSetItem(ORDER_DATA, order);

      return {
        ...state,
        order,
      };
    }

    case SET_USER_ORDER: {
      const order = {
        ...state.order,
        ...payload,
      };

      return {
        ...state,
        order,
      };
    }

    case SET_ORDER_FREE_LIMIT: {
      const order = {
        ...state.order,
        minFreeLimit: payload,
      };

      localStorageSetItem(ORDER_DATA, order);

      return {
        ...state,
        order,
      };
    }

    case SET_USER: {
      return {
        ...state,
        user: payload,
      };
    }

    case SET_IS_LOADED: {
      return {
        ...state,
        isLoaded: true,
      };
    }

    case LOGOUT_USER: {
      logOut(false, action.payload);

      return {
        ...initialState,
        cart: state.cart,
        cartItemsTotal: state.cartItemsTotal,
        cartSumTotal: state.cartSumTotal,
      };
    }

    default:
      return state;
  }
};
export default reducer;
