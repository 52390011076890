import { MOLLIE_PAYMENT, DIRECT_BANK_TRANSFER } from "data/constants";

export const getMolliePayment = (method) =>
  method.includes(MOLLIE_PAYMENT) ? method.replace(MOLLIE_PAYMENT, "") : method;

export const generatePaymentTitle = (title) =>
  title.indexOf("Mollie - ") > -1 ? title.slice("Mollie - ".length, title.length) : title;

export const isMolliePayment = (methodId) => methodId.indexOf(MOLLIE_PAYMENT) > -1;
export const isDirectBankPayment = (methodId) => methodId === DIRECT_BANK_TRANSFER;
