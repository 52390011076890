import axios from "axios";
import { AUTH_TOKEN, HTTP_FORBIDDEN, HTTP_UNATHORIZED, REFRESH_TOKEN_URL } from "data/constants";
import { ISSERVER, sessionStorageGetItem } from "helpers";
import { logOutUser } from "context/eshop/eshopActions";
import { userIsLogged } from "context/eshop/EshopState";
import { setIsLoading } from "context/app/appActions";
import { eshopStore } from "context/eshop/EshopState";
import store from "context/app/appContext";
import { logOut } from "./woocommerce";

const instance = axios.create({
  baseURL: process.env.WORDPRESS_BASE_URL,
});

instance.defaults.headers.common["Accept"] = "application/json";
instance.defaults.headers.patch["Content-Type"] = "application/json";
instance.defaults.headers.put["Content-Type"] = "application/json";
instance.defaults.headers.post["Content-Type"] = "application/json";
instance.defaults.headers.delete["Content-Type"] = "application/json";

if (!ISSERVER) {
  instance.defaults.headers.common["Authorization"] = "Bearer " + sessionStorageGetItem(AUTH_TOKEN);
}

instance.interceptors.request.use(
  (config) => {
    if (!ISSERVER && !config?.url?.includes(REFRESH_TOKEN_URL)) {
      store.dispatch(setIsLoading(true));
    }
    config.params = config.params || {};

    return config;
  },
  (error) => {
    if (!ISSERVER) {
      store.dispatch(setIsLoading(false));
    }
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (!ISSERVER) {
      store.dispatch(setIsLoading(false));
    }
    return response;
  },
  (error) => {
    if (!ISSERVER) {
      store.dispatch(setIsLoading(false));
    }
    if (
      (HTTP_UNATHORIZED === error?.response?.status && error?.response?.data?.code === "rest_forbidden") ||
      HTTP_FORBIDDEN === error?.response?.status
    ) {
      if (userIsLogged.isLoggedIn && !error?.config?.url?.includes(REFRESH_TOKEN_URL)) {
        store.dispatch(setIsLoading(true));
        return new Promise((resolve) => {
          setTimeout(() => {
            error.config.headers["Authorization"] = "Bearer " + sessionStorageGetItem(AUTH_TOKEN);

            instance
              .request(error.config)
              .then((data) => {
                resolve(data);
                store.dispatch(setIsLoading(false));
              })
              .catch(() => {
                eshopStore.dispatch(logOutUser());
                logOut(true);
              });
          }, 3000);
        });
      }

      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
