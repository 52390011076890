import productsTree from "data/productsTree.json";
import { isArrayDefined } from "helpers";
import { focus_menu } from "data/constants";

export const filterAndFindCategory = ({ categories, categoriesNames, categorySlug }) => {
  // filter matching categories
  const filteredCategories = categories.filter(({ name }) => categoriesNames.indexOf(name) > -1);

  let category = null;
  // loop categories and find category by slug
  const loopCategories = (cats) => {
    cats.forEach((item) => {
      if (item.slug === categorySlug) {
        category = item;
      }

      if (item.children && item.children.length) {
        loopCategories(item.children);
      }
    });
  };

  loopCategories(filteredCategories);

  return category;
};

export const createSlugPath = (array, categoriesToFound) => {
  const createPaths = (items, path = []) =>
    items.map(({ slug, name, id, children }, _, __, newPath = [...path, slug]) => ({
      slugPath: newPath.join("/"),
      name,
      id,
      ...(children ? { children: createPaths(children, newPath) } : {}),
    }));

  const slugsPaths = createPaths(array);

  const getAncestors = (targets, children, ancestors = []) => {
    for (let node of children) {
      if (targets.indexOf(node.id) > -1) {
        return ancestors.concat({ name: node.name, slugPath: node.slugPath, id: node.id });
      }
      const found = getAncestors(
        targets,
        node.children,
        ancestors.concat({ name: node.name, slugPath: node.slugPath, id: node.id })
      );
      if (found) return found;
    }
    return undefined;
  };

  const paths = getAncestors(
    categoriesToFound.map(({ id }) => id),
    slugsPaths
  );

  const pathsCategoryFocus = !!focus_menu
    .map((item) => item.url)
    .find((url) => url.includes(paths[0].slugPath));

  paths.forEach((path) => (path.root = pathsCategoryFocus ? "focus" : "category"));

  return paths;
};

export const filterSubCategoriesAndCreatePaths = ({ categories, categoriesNames }) => {
  // filter matching categories
  const filteredCategories = categories.filter(({ name }) => categoriesNames.indexOf(name) > -1);

  // loop nested categories and create slug path
  const addSlugPath = (items, path = []) =>
    items.map(({ slug, children, ...rest }, _, __, newPath = [...path, slug]) => ({
      ...rest,
      slug,
      slugPath: newPath.join("."),
      ...(children ? { children: addSlugPath(children, newPath) } : {}),
    }));
  const newcategories = addSlugPath(filteredCategories);

  // loop nested categories and create array of slugs
  const loopAndSplitArr = (categoriesArr) => {
    const arr = [];
    // create flatten array of strings
    const loop = (nestedArr) => {
      nestedArr.forEach((item) => {
        item.children.length ? loop(item.children) : arr.push(item.slugPath);
      });
    };

    loop(categoriesArr);

    // split string into array of strings
    let newArr = [];
    arr.forEach((item) => {
      const splittedArr = item.split(".");
      newArr.push(splittedArr);
    });

    return newArr;
  };

  const splittedArray = loopAndSplitArr(newcategories);

  return splittedArray.filter((item) => item.length > 1);
};

export const filterCategories = ({ categories, categoriesNames }) =>
  categories.filter(({ name }) => categoriesNames.indexOf(name) > -1);

export const findCategory = ({ categories, categoryName }) =>
  categories.find(({ slug }) => slug.includes(categoryName));

let foundCategory;
const loopAndFindCategory = (categoriesTree, catId) => {
  categoriesTree.forEach((category) => {
    if (category.id === catId) {
      foundCategory = category;
    } else if (isArrayDefined(category.children)) {
      loopAndFindCategory(category.children, catId);
    }
  });
  return foundCategory;
};

const categoryProducts = [];
const loopAndGetCategoriesProducts = (category) => {
  category.forEach((item) => {
    if (item?.products) categoryProducts.push(item?.products);
    if (isArrayDefined(item.children)) loopAndGetCategoriesProducts(item.children);
  });
};

export const findCategoriesProducts = (categoryId) => {
  const categoryWithProducts = loopAndFindCategory(productsTree, categoryId);

  loopAndGetCategoriesProducts([categoryWithProducts]);

  if (isArrayDefined(categoryProducts)) {
    const products = categoryProducts.flat();
    categoryProducts.length = 0;
    return products;
  }
  return [];
};

const findNextProducts = (products, categoryId) => {
  const categoryWithProducts = loopAndFindCategory(products, categoryId);

  loopAndGetCategoriesProducts([categoryWithProducts]);

  if (isArrayDefined(categoryProducts)) {
    const products = categoryProducts.flat();
    categoryProducts.length = 0;
    return products;
  }
  return [];
};

export const fetchAndSetProducts = async ({ categoryId, defaultProducts, setRows }) => {
  const productTree = await require("data/productsTree.json");
  const categoryProducts = findNextProducts(productTree, categoryId);
  const newproducts = [...defaultProducts, ...categoryProducts.slice(50, categoryProducts.length)];
  setRows(newproducts);
};

const findDeep = (pred) => (xs) => {
  for (let x of xs) {
    if (pred(x)) {
      return [x];
    }
    const c = findDeep(pred)(x.children || []);
    if (c) return [{ ...x, children: c }];
  }
};

const findDeepById = (target) => findDeep((x) => x.id == target);

export const getCategoryTree = (categoryId, categoriesTree) => {
  const [categoryTree] = findDeepById(categoryId)(categoriesTree);
  const mainCategoryId = categoryTree?.id;
  if (mainCategoryId) {
    return categoriesTree.find(({ id }) => id === mainCategoryId);
  }
};
