import {
  CHANGE_PASSWORD_URL,
  LOST_PASSWORD_URL,
  REGISTER_FORM_URL,
  USER_PROFILE_URL,
  LOGIN_FORM_URL,
  REFRESH_TOKEN,
  HTTP_CREATED,
  AUTH_TOKEN,
  HTTP_OK,
} from "data/constants";
import {
  createChangePasswordData,
  createLostPasswordData,
  createCustomerData,
  createShippingData,
  createBillingData,
  createProfileData,
  createLoginData,
  resetInvoiceAndDeliveryAddress,
} from "helpers/woocommerce";
import { setUserAddresses, setUserLogin } from "context/eshop/eshopActions";
import { setToastFormError, setToastFormSuccess } from "utils/toast";
import { localStorageSetItem, sessionStorageSetItem } from "helpers";
import { getWoocommerceQuery, woocommercePost } from "./";
import { setToast } from "context/app/appActions";
import axios from "utils/axios";

// Register query
export const registerUser = async ({ data, setRegisterState, dispatch }) => {
  setRegisterState(false);
  const formData = createCustomerData(data);

  try {
    const { data } = await axios.post(process.env.WOOCOMMERCE_URL + REGISTER_FORM_URL, formData);

    if (data?.data?.code === HTTP_OK && data?.data?.message) {
      setRegisterState(true);
      dispatch(setToast(setToastFormSuccess(data.data.message)));
    }
  } catch (e) {
    setRegisterState(false);
    dispatch(setToast(setToastFormError(e?.response?.data?.error?.message)));
  }
};

// Login query
export const loginUser = async ({ data, dispatchEshop, setIsLoading, setSuccess, dispatch, onSuccess }) => {
  const formData = createLoginData(data);
  setIsLoading(true);

  try {
    const {
      data: { data },
    } = await axios.post(process.env.WOOCOMMERCE_URL + LOGIN_FORM_URL, formData);

    if (data?.code === HTTP_CREATED && data?.accessToken) {
      sessionStorageSetItem(AUTH_TOKEN, data.accessToken);
      localStorageSetItem(REFRESH_TOKEN, data.refreshToken);
      axios.defaults.headers.common["Authorization"] = "Bearer " + data.accessToken;
      dispatchEshop(setUserLogin(data?.user));

      getUserAddressess({ dispatchEshop, onSuccess, setSuccess, setIsLoading });
    }
  } catch (e) {
    setIsLoading(false);
    dispatch(setToast(setToastFormError(e?.response?.data?.error?.message)));
  }
};

// Profile query
export const getUserAddressess = async ({ dispatchEshop, onSuccess, setSuccess, setIsLoading }) => {
  try {
    const {
      data: { billing_address, shipping_address },
    } = await getWoocommerceQuery(USER_PROFILE_URL);

    if (billing_address && shipping_address) {
      setIsLoading(false);
      setSuccess(true);
      dispatchEshop(setUserAddresses({ billing_address, shipping_address }));
      onSuccess();
    }
  } catch (e) {
    return console.error(e);
  }
};

// Store Profile data query
export const storeUserData = async ({ data, dispatch, dispatchEshop, setEditMode, reset }) => {
  const shippingAddress = data.same_as_billing ? createBillingData(data) : createShippingData(data);
  const userProfileData = createProfileData(data, shippingAddress);

  reset(resetInvoiceAndDeliveryAddress(data, shippingAddress));

  try {
    const { data } = await woocommercePost(USER_PROFILE_URL, userProfileData);

    if (data?.id) {
      dispatchEshop(
        setUserAddresses({
          billing_address: userProfileData.data.billing_address,
          shipping_address: userProfileData.data.shipping_address,
        })
      );
      dispatch(setToast(setToastFormSuccess("addresses-stored")));
      setEditMode(false);
      return;
    }

    dispatch(setToast(setToastFormError("addresses-error")));
  } catch (e) {
    dispatch(setToast(setToastFormError(e?.response?.data?.error?.message)));
  }
};

// Reset password
export const changeUserPassword = async ({ data, setSuccess, dispatch }) => {
  const formData = JSON.stringify(createChangePasswordData(data));

  try {
    const {
      data: { data },
    } = await axios.post(process.env.WOOCOMMERCE_URL + CHANGE_PASSWORD_URL, formData);

    if (data?.code === HTTP_OK && data?.message) {
      setSuccess(true);
      dispatch(setToast(setToastFormSuccess("password-change-success")));
    }
  } catch (e) {
    console.error(e);
    dispatch(setToast(setToastFormError(e?.response?.data?.error?.message)));
  }
};

// Forgotten password
export const forgottenUserPassword = async ({ data, setSuccess, dispatch }) => {
  const formData = createLostPasswordData(data);

  try {
    const {
      data: { data },
    } = await axios.post(process.env.WOOCOMMERCE_URL + LOST_PASSWORD_URL, formData);

    if (data?.code === HTTP_OK && data?.message) {
      setSuccess(true);
      dispatch(setToast(setToastFormSuccess(data?.message)));
    }
  } catch (e) {
    dispatch(setToast(setToastFormError(e?.response?.data?.error?.message)));
  }
};
