// Dates
export const getCurrentDate = () => {
  const date = new Date();
  return date.getDate() + ". " + (date.getMonth() + 1) + ". " + date.getFullYear();
};

export const parseDate = (dateString) => {
  const date = new Date(dateString);
  return date.getDate() + ". " + (date.getMonth() + 1) + ". " + date.getFullYear();
};
