import React, { useEffect } from "react";
import { appWithTranslation } from "next-i18next";
import { Provider } from "react-redux";
import EshopState from "context/eshop/EshopState";
import store from "context/app/appContext";
import { ISSERVER } from "helpers";
import "styles/main.scss";

const onLoaded = () => document.body.classList.add("loaded");

const App = ({ Component, pageProps }) => {
  useEffect(() => {
    if (!ISSERVER) onLoaded();
  }, []);

  return (
    <Provider store={store}>
      <EshopState>
        <Component {...pageProps} />
      </EshopState>
    </Provider>
  );
};

export default appWithTranslation(App);
